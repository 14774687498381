import React from "react";
import { SEO, PageLayout, Content, Image } from "@bluefin/components";
import { Grid, Header, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class GeorgetownGhostStoryPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"about-more-page"}>
          <Grid stackable={true} columns={1} textAlign={"center"}>
            <Grid.Column width={10}>
              <Content className={"about-content"} textAlign={"center"}>
                <Header as={"h1"} className={"about-us-header"}>
                  GEORGETOWN GHOST STORY
                </Header>
                <Image
                  className={"image"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/3b94a099-a24e-4152-83e2-7a1382a7e9f9/1_3UyTgXI.png"
                  }
                />
                <Header as={"h3"} className={"about-us-header"}>
                  by Paul Dale Roberts, HPI Ghostwriter
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <div>
                        It's 10 a.m. and HPI paranormal investigator, Hanako
                        Zeidenberg is in my driveway. Hanako has driven all the
                        way from Oakland, CA to do a scouting mission to
                        Georgetown. Georgetown is located deep in the valley on
                        Hwy 49 between Placerville, CA and Auburn, CA. Hanako
                        jumps into my vehicle, known as the Ghost Tracker, and
                        we head up towards Hwy 80 to pick up paranormal
                        investigator / sensitive, Holly DeLaughter. After
                        driving down historic Hwy 49 through a zillion winding
                        roads to get to Georgetown, we stop first at the
                        Georgetown Hotel. We meet up with HPI paranormal
                        investigator / researcher Michele Stump and her
                        boyfriend Scott. I introduce myself to the bartender of
                        the Georgetown Hotel, and tell her I am a paranormal
                        journalist for HPI and I am here to do a story on their
                        haunted hotel. She is full of ghost stories and is eager
                        to share them with me.
                      </div>
                      <p>
                        Let's run down some of the stories that haunt this
                        place:
                      </p>
                      <List
                        items={[
                          "There was a child three years old that died in his sleep. People sometimes hear a ball bouncing downstairs and they believe it's that child.",
                          "The water in the sink goes off and on - on its own accord.",
                          "Her young son was playing ball with a phantom boy child.",
                          "People hear a baby's laughter at times and hear footsteps walking down the stairs.",
                          "One-time pipe smoke was blown into her face from an unknown source.",
                          "Her son witnessed a locked door open by itself.",
                          "A transparent nightgown was seen floating upstairs.",
                          "There is a mural painting on the dining room wall and there is a picture of an ominous silhouette of a cowboy. That cowboy silhouette was never painted on that mural and one day it appeared and has been there ever since.",
                          "Big Dave was a caretaker of the hotel and he was found dead on the toilet from an apparent heart attack. Big Dave is known to haunt the hotel. He is very playful with the clients. Holly used her psychic abilities to determine that there was a man sitting on a toilet that died of a heart attack. She had this vision of Big Dave before she was told the story of Big Dave.",
                          "That was pretty amazing to validate this information from the bartender.",
                          "Patrons of the hotel have said they were touched while walking to the kitchen of the hotel.",
                        ]}
                      />
                      <p>
                        They gave the HPI crew a full tour of various rooms in
                        the hotel, the 2nd floor and even the basement. The
                        basement is connected to a mineshaft. Before we went
                        down a there, Holly felt like there was a mineshaft
                        under the hotel and again, she was right on the money.
                        Her psychic abilities were arrow straight today. There
                        is more information on the Georgetown Hotel at
                        www.georgetownhotelandsaloon.com
                      </p>
                      <p>
                        ***The most famous ghost that haunts this hotel is
                        Merna. She usually hangs out in Room #5. Hotel workers
                        found photo albums and sketch books of Merna's in this
                        hotel room. Merna was a lady of the night. One night
                        Merna and her boyfriend got into a heated argument and
                        her boyfriend threw her off the 2nd floor balcony
                        breaking her neck, killing her. In Room #5 the drapes on
                        the window sometimes move back and forth with the
                        windows closed. As I was using temperature gauge, the
                        normal temperature of the room was anywhere from 63 to
                        71 degrees, but at one point it went up to 95 degrees,
                        indicating a hot spot. Holly got a feeling that a man
                        died in Room #10 watching TV. It was confirmed that a
                        man named Butch did indeed die in this room watching TV.
                        Holly felt energy presences also in Room #1 and Room
                        #11. The staff provided us with loads of good
                        information and were very helpful.
                      </p>
                      <p>
                        As we left the Georgetown Hotel and Saloon we witnessed
                        a posted certificate, it read: "This Hotel is certified
                        haunted." Signed by none other than the legendary "Ghost
                        Girl", Nancy Bradley! In addition, the University of
                        California Berkley has certified the Hotel as one of the
                        top ten most haunted buildings in California...do you
                        believe!?!?
                      </p>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Georgetown Ghost Story" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
